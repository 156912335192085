<template>
  <div class="goldDetail">
    
    <h2 class="tc mb">积分/金币获取途径</h2>
    <table>
      <tbody>
        <tr>
          <td style="width: 41.5%">细则</td>
          <td style="width: 17%">金币 / 积分</td>
          <td>备注</td>
        </tr>
        <tr>
          <td>个人信息</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>注册</td>
          <td>20</td>
          <td>通过手机号/邮箱完成</td>
        </tr>
        <tr>
          <td>完善资料</td>
          <td>10</td>
          <td></td>
        </tr>
        <tr>
          <td>绑定邮箱/手机/微信</td>
          <td>30</td>
          <td></td>
        </tr>
        <tr>
          <td>每日签到</td>
          <td>2</td>
          <td></td>
        </tr>
        <tr>
          <td>连续7日签到</td>
          <td>10</td>
          <td>积分加成，每日签到积分仍可获得</td>
        </tr>
        <tr>
          <td>剑盟码邀请注册</td>
          <td>100</td>
          <td>
            填写邀请码成功注册后，邀请者与注册者均获得100金币的奖励。
          </td>
        </tr>
        <tr>
          <td>习剑</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>学习资料（观看视频超过5分钟）</td>
          <td>2</td>
          <td>每日上限20</td>
        </tr>
        <tr>
          <td>学习资料（下载资料）</td>
          <td>2</td>
          <td>每日上限20</td>
        </tr>
        <tr>
          <td>分享学习资料</td>
          <td>5</td>
          <td>每日上限20</td>
        </tr>
        <tr>
          <td>驭剑</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>发表帖子</td>
          <td>10</td>
          <td>每日上限50</td>
        </tr>
        <tr>
          <td>点赞帖子</td>
          <td>1</td>
          <td>每日上限5</td>
        </tr>
        <tr>
          <td>收藏帖子</td>
          <td>2</td>
          <td>每日上限10</td>
        </tr>
        <tr>
          <td>评论帖子</td>
          <td>2</td>
          <td>每日上限10</td>
        </tr>
        <tr>
          <td>帖子被回复/评论</td>
          <td>2</td>
          <td>每日上限10</td>
        </tr>
        <tr>
          <td>帖子被收藏</td>
          <td>5</td>
          <td>每日上限20</td>
        </tr>
        <tr>
          <td>帖子被打赏（金币）</td>
          <td>5/10/20</td>
          <td>
            打赏者打赏的金币转入被打赏者账户，打赏后：总积分需>=50且积分需>0；总积分>=50才可被打赏
          </td>
        </tr>
        <tr>
          <td>帖子进入热榜的TOP 前10</td>
          <td>50</td>
          <td>
            进入剑盟论坛热榜TOP前10的用户，每个ID可获得50积分。每周进行一次统计，次周周一24点前下发奖励积分
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { GetWxShares } from "@/utils/common";

export default {
  name: "goldDetail",
  data() {
    return {};
  },
  mounted(){
    const share = {
      title: 'AVEVA 社区微站金币获取途径详情',
      desc: ''
    }
    GetWxShares(share);
  }
};
</script>

<style lang="scss">
	.goldDetail {
		max-width: 1200px;padding: 20px;margin: 5px auto;
	}
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #aaa;
  tr {
    td {
      vertical-align: baseline;
      padding: 10px;
      border: 1px solid #3f3f3f;
      text-align: left;
      color: #686868;
    }
    &:first-of-type {
      background-color: #6b18a8;
      td {
        color: #fff;
      }
    }
  }
  td {
    padding: 10px;
  }
}
</style>